<template>
	<div class="">
		<h2>建设中...</h2>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
				
			}
		},
		created(){
			
		
		},
		
		methods:{

		}
	}
</script>

<style>
	h2{
		margin-top: 50px;
		color: #666;
		/* font-size: ; */
	}
</style>
